<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Sectores</b-card-title>
        <b-button @click="createSector" variant="primary" :disabled="loading">
          Crear
        </b-button>
      </b-card-header>

      <b-card-body>
        <!-- table -->
        <vue-good-table
          :rows="rows"
          :columns="columns"
          :isLoading.sync="loading"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editSector($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="editLocations($event, props.row)">
                    <feather-icon icon="MapPinIcon" class="mr-50" />
                    <span>Ubicacion</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <b-pagination
                align="right"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                :value="1"
                :per-page="pageLength"
                last-number
                :total-rows="props.total"
                first-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
    <!-- modal crear-editar sector -->
    <b-modal
      id="modal-sector"
      :title="modalSectorTitle"
      v-model="showCreateEditSectorModal"
      centered
      hide-footer
    >
      <b-form-group>
        <label for="vi-sector-nombre">Nombre</label>
        <b-form-input
          id="vi-sector-nombre"
          v-model="selectedSector.nombre"
          placeholder="Nombre del sector"
        />
      </b-form-group>
      <b-form-group>
        <label for="vi-sector-telefono">Telefono</label>
        <b-form-input
          id="vi-sector-telefono"
          v-model="selectedSector.telefono"
          placeholder="Telefono"
        />
      </b-form-group>
      <b-form-group>
        <label for="vi-sector-descripcion">Descripcion</label>
        <b-form-input
          id="vi-sector-descripcion"
          v-model="selectedSector.descripcion"
          placeholder="Descripcion"
        />
      </b-form-group>
      <b-button
        class="float-right"
        @click="saveSector"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-modal>
    <!-- modal crear-editar ubicacion -->
    <b-modal
      id="modal-ubicacion"
      size="lg"
      @hide="onHideLocationModal"
      :title="modalUbicacionTitle"
      v-model="showCreateEditLocationModal"
      centered
      hide-footer
    >
      <b-row>
        <b-col cols="12">
          <b-button
            class="float-right"
            @click="createLocation"
            variant="primary"
            :disabled="loading"
          >
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Crear</div>
          </b-button>
        </b-col>
        <b-col cols="12" md="5">
          <b-form-group label="Ubicaciones" label-for="v-ubicaciones-s">
            <v-select
              id="v-ubicaciones-s"
              label="ubicacionNombre"
              :options="locations"
              :loading="loadingLocations"
              :clearable="false"
              placeholder="Seleccione una ubicacion"
              :filterable="true"
              @option:selected="onSelectLocation"
            />
          </b-form-group>
          <div v-if="selectedLocation">
            <b-form-group>
              <label for="vi-ubicacion-nombre">Nombre</label>
              <b-form-input
                id="vi-ubicacion-nombre"
                v-model="selectedLocation.ubicacionNombre"
                placeholder="Nombre del sector"
              />
            </b-form-group>
            <b-form-group>
              <label for="vi-ubicacion-envio">Precio de envio</label>
              <b-form-input
                id="vi-ubicacion-envio"
                v-model="selectedLocation.costoEnvio"
                placeholder="Precio de envio"
              />
            </b-form-group>
            <b-button
              block
              class="mt-2"
              @click="saveLocation"
              variant="primary"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="7">
          <div id="map-canvas" style="width: 430px; height: 300px"></div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
  BDropdown,
  BCardBody,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BCardHeader,
  BPagination,
  BFormSelect,
  BDropdownItem,
} from "bootstrap-vue";
let isBeingDragged = false;
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import { getUserID, getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    vSelect,
    BSpinner,
    BDropdown,
    BCardBody,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardHeader,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
  },
  props: {
    warehouseID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      columns: [
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      loading: true,
      locations: [],
      pageLength: 4,
      selectedSector: {},
      isBeingDragged: false,
      selectedLocation: null,
      loadingLocations: true,
      showCreateEditSectorModal: false,
      showCreateEditLocationModal: false,
    };
  },
  created() {
    this.getSectors();
  },
  methods: {
    getSectors() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/AdminSectores",
          body: JSON.stringify({
            opt: "BD",
            canal: "APP",
            usuario: "sa",
            empresa: 2,
            bodega_id: this.warehouseID,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.rows = res.data.lstSectores;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
        });
    },
    getLocations(sectorID) {
      this.loadingLocations = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SectorUbicacion",
          body: JSON.stringify({
            otp: "V",
            empresa: 2,
            IDSeector: sectorID,
          }),
        })
        .then((res) => {
          this.loadingLocations = false;
          if (res.data.bOk) {
            if (res.data.sectoresUbicacion) {
              this.locations = res.data.sectoresUbicacion;
            } else {
              this.error = "El servicio no retorno data.";
            }
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.loadingLocations = false;
        });
    },
    createSector(event) {
      event.preventDefault();
      this.selectedSector = {
        nombre: "",
        idSector: 0,
        telefono: "",
        descripcion: "",
      };
      this.showCreateEditSectorModal = true;
    },
    createLocation(event) {
      event.preventDefault();
      this.selectedLocation = {
        coordenadas: "[]",
        idSectorUbicacion: 0,
      };
      this.$nextTick(this.loadMapDrawing);
    },
    editSector(event, sector) {
      event.preventDefault();
      this.selectedSector = sector;
      this.showCreateEditSectorModal = true;
    },
    editLocations(event, sector) {
      event.preventDefault();
      this.getLocations(sector.idSector);
      this.selectedSector = sector;
      this.showCreateEditLocationModal = true;
    },
    onSelectLocation(location) {
      this.selectedLocation = location;
      this.$nextTick(this.loadMapEdit);
    },
    updatePaths(mvcArray) {
      this.selectedLocation.coordenadas = JSON.stringify(
        mvcArray.map((point) => [point.lat(), point.lng()])
      );
    },
    saveSector(event) {
      event.preventDefault();
      this.loading = true;
      this.selectedSector.id = this.selectedSector.idSector;
      this.selectedSector.opt = "IS";
      this.selectedSector.canal = "APP";
      this.selectedSector.usuario = `${getUserID()}`;
      this.selectedSector.empresa = 2;
      this.selectedSector.bodega_id = this.warehouseID;
      this.selectedSector.tokenSesion= getAuthToken(),

      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/AdminSectores",
          body: JSON.stringify(this.selectedSector),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getSectors();
            this.showCreateEditSectorModal = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text:
                  this.selectedSector.id === 0
                    ? "Se creo correctamente el sector."
                    : "Se actualizo correctamente el sector.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    saveLocation(event) {
      event.preventDefault();
      this.loading = true;
      this.selectedLocation.opt = this.selectedSector.id === 0 ? "I" : "U";
      this.selectedLocation.usuario = `${getUserID()}`;
      this.selectedLocation.empresa = 2;
      this.selectedLocation.IDSeector = this.selectedSector.idSector;
      this.selectedLocation.CosoEnvio = this.selectedLocation.costoEnvio;
      this.selectedLocation.tokenSesion = getAuthToken();
      this.selectedLocation.NombreUbicacion =
        this.selectedLocation.ubicacionNombre;
      this.selectedLocation.IDSectorUbicacion =
        this.selectedLocation.idSectorUbicacion;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SectorUbicacion",
          body: JSON.stringify(this.selectedLocation),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getSectors();
            this.showCreateEditLocationModal = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text:
                  this.selectedLocation.idSectorUbicacion === 0
                    ? "Se creo correctamente la Ubicacion."
                    : "Se actualizo correctamente la Ubicacion.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: res.data.mensaje,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    loadMapDrawing() {
      const self = this;
      const map = new google.maps.Map(document.getElementById("map-canvas"), {
        zoom: this.zoomLevel,
        center: this.center,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      });
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
          fillColor: "green",
          strokeColor: "green",
          strokeWeight: 3,
          strokeOpacity: 0.8,
        },
      });
      drawingManager.setMap(map);
      google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        (polygon) => {
          self.updatePaths(polygon.getPath().getArray());
          google.maps.event.addListener(polygon, "dragstart", () => {
            isBeingDragged = true;
          });
          google.maps.event.addListener(polygon, "dragend", () => {
            isBeingDragged = false;
            self.updatePaths(polygon.getPath().getArray());
          });
          google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
            self.updatePaths(polygon.getPath().getArray());
          });
          google.maps.event.addListener(polygon.getPath(), "set_at", () => {
            !isBeingDragged && self.updatePaths(polygon.getPath().getArray());
          });
          google.maps.event.addListener(polygon.getPath(), "remove_at", () => {
            self.updatePaths(polygon.getPath().getArray());
          });
        }
      );
    },
    loadMapEdit() {
      const self = this;
      const map = new google.maps.Map(document.getElementById("map-canvas"), {
        zoom: this.zoomLevel,
        center: this.center,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      });
      const locationPolygon = new google.maps.Polygon({
        paths: this.polygon,
        editable: true,
        draggable: true,
        fillColor: "green",
        strokeColor: "green",
        strokeWeight: 3,
        strokeOpacity: 0.8,
      });
      locationPolygon.setMap(map);
      google.maps.event.addListener(locationPolygon, "dragstart", () => {
        isBeingDragged = true;
      });
      google.maps.event.addListener(locationPolygon, "dragend", () => {
        isBeingDragged = false;
        self.updatePaths(locationPolygon.getPath().getArray());
      });
      google.maps.event.addListener(
        locationPolygon.getPath(),
        "insert_at",
        () => {
          self.updatePaths(locationPolygon.getPath().getArray());
        }
      );
      google.maps.event.addListener(locationPolygon.getPath(), "set_at", () => {
        !isBeingDragged &&
          self.updatePaths(locationPolygon.getPath().getArray());
      });
      google.maps.event.addListener(
        locationPolygon.getPath(),
        "remove_at",
        () => {
          self.updatePaths(locationPolygon.getPath().getArray());
        }
      );
    },
    onHideLocationModal() {
      this.selectedLocation = null;
    },
  },
  computed: {
    center() {
      if (this.polygon.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        this.polygon.forEach((coord) => bounds.extend(coord));
        return bounds.getCenter();
      }
      return { lat: -1.4692803, lng: -78.8256944 };
    },
    polygon() {
      const points = JSON.parse(this.selectedLocation.coordenadas);
      return points.map((c) => ({ lat: c[0], lng: c[1] }));
    },
    zoomLevel() {
      if (this.polygon.length > 0) {
        return 13;
      } else {
        return 7;
      }
    },
    modalSectorTitle() {
      if (this.selectedSector.idSector !== 0)
        return `Editar sector [${this.selectedSector.nombre}]`;
      return "Crear sector";
    },
    modalUbicacionTitle() {
      return `Ubicaciones del sector [${this.selectedSector.nombre}]`;
    },
  },
};
</script>
<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
