var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.error.length > 0 ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": true
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Ocurrio un error al obtener los datos [" + _vm._s(_vm.error) + "] ")])]) : _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "p-3"
  }, [_c('h2', {
    staticClass: "mb-2"
  }, [_vm._v("Modificar " + _vm._s(_vm.warehouse.nombreBodega))]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre Bodega",
      "label-for": "vi-nombrebodega"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-nombrebodega",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.nombreBodega,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "nombreBodega", $$v);
      },
      expression: "warehouse.nombreBodega"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "ID Código Contifico",
      "label-for": "vi-idcodigocontifico"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-idcodigocontifico",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.idCodigoContifico,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "idCodigoContifico", $$v);
      },
      expression: "warehouse.idCodigoContifico"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Código Contifico",
      "label-for": "vi-codigocontifico"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-codigocontifico",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.codigoContifico,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "codigoContifico", $$v);
      },
      expression: "warehouse.codigoContifico"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "ID Bodega Contifico",
      "label-for": "vi-idbodegacontifico"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-idbodegacontifico",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.idCodigoBodegaContifico,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "idCodigoBodegaContifico", $$v);
      },
      expression: "warehouse.idCodigoBodegaContifico"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telefono",
      "label-for": "vi-telefono"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-telefono",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "telefono", $$v);
      },
      expression: "warehouse.telefono"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Secuencia",
      "label-for": "vi-secuencia"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-secuencia",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.bg_Secuencua,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "bg_Secuencua", $$v);
      },
      expression: "warehouse.bg_Secuencua"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "POS Token API",
      "label-for": "vi-postokenapi"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-postokenapi",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.pos_token_contifico,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "pos_token_contifico", $$v);
      },
      expression: "warehouse.pos_token_contifico"
    }
  })], 1), _c('b-form-text', [_vm._v("Token del POS asignado de contifico")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Establecimiento",
      "label-for": "vi-establecimiento"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-establecimiento",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.establecimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "establecimiento", $$v);
      },
      expression: "warehouse.establecimiento"
    }
  })], 1), _c('b-form-text', [_vm._v("Establecimiento del POS asignado de contifico")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Punto de emisión",
      "label-for": "vi-puntoemision"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-puntoemision",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.puntoEmision,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "puntoEmision", $$v);
      },
      expression: "warehouse.puntoEmision"
    }
  })], 1), _c('b-form-text', [_vm._v("Punto Emisión del POS asignado de contifico")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mensaje bodega cerrada",
      "label-for": "vi-msjcerrarbodega"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-msjcerrarbodega",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.mensaje_cerrado,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "mensaje_cerrado", $$v);
      },
      expression: "warehouse.mensaje_cerrado"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "m-0",
    attrs: {
      "label": "Codigo Local",
      "label-for": "vi-cod-local"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-cod-local",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.bP_codLocal,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "bP_codLocal", $$v);
      },
      expression: "warehouse.bP_codLocal"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "m-0",
    attrs: {
      "label": "MID",
      "label-for": "vi-mid"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mid",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.bP_Mid,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "bP_Mid", $$v);
      },
      expression: "warehouse.bP_Mid"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "m-0",
    attrs: {
      "label": "TID",
      "label-for": "vi-tid"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-tid",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.bP_Tid,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "bP_Tid", $$v);
      },
      expression: "warehouse.bP_Tid"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-text', [_vm._v("Datos proporcionados por El Rosado")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Token Gacela Market",
      "label-for": "vi-tokengacelamarket"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-tokengacelamarket",
      "placeholder": ""
    },
    model: {
      value: _vm.warehouse.tokenGacela,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "tokenGacela", $$v);
      },
      expression: "warehouse.tokenGacela"
    }
  })], 1), _c('b-form-text', [_vm._v("Manejo de envio con terceros. Market y Tiendas en Eats")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12 mt-2"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Opciones"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.warehouse.statusBod,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "statusBod", $$v);
      },
      expression: "warehouse.statusBod"
    }
  }, [_vm._v(" Activa ")]), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.warehouse.bg_Bloqueo,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "bg_Bloqueo", $$v);
      },
      expression: "warehouse.bg_Bloqueo"
    }
  }, [_vm._v(" Bloqueada ")]), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.warehouse.inmediata,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "inmediata", $$v);
      },
      expression: "warehouse.inmediata"
    }
  }, [_vm._v(" Permite compras inmediatas ")]), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.warehouse.programada,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "programada", $$v);
      },
      expression: "warehouse.programada"
    }
  }, [_vm._v(" Permite compras programadas ")]), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.warehouse.market,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "market", $$v);
      },
      expression: "warehouse.market"
    }
  }, [_vm._v(" Acepta Gacela Market ")]), _c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.warehouse.eats,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "eats", $$v);
      },
      expression: "warehouse.eats"
    }
  }, [_vm._v(" Acepta Gacela Asociados ")]), _c('b-form-radio-group', {
    staticClass: "mb-3",
    attrs: {
      "options": _vm.bodegaTypes,
      "value-field": "item",
      "text-field": "name"
    },
    model: {
      value: _vm.warehouse.bg_type,
      callback: function callback($$v) {
        _vm.$set(_vm.warehouse, "bg_type", $$v);
      },
      expression: "warehouse.bg_type"
    }
  }), _c('horarios', {
    attrs: {
      "data": _vm.warehouse.bg_horario,
      "warehouseID": _vm.warehouse.idBodega
    }
  })], 1), _c('sectores', {
    attrs: {
      "warehouseID": _vm.warehouse.idBodega
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }