<template>
  <div>
    <b-button block class="mt-1" variant="primary" @click="onClick"
      >Horarios</b-button
    >
    <b-sidebar
      id="sidebar-horarios-bg"
      right
      width="480px"
      shadow
      v-model="show"
      backdrop
      body-class="p-2"
      bg-variant="white"
    >
      <b-row>
        <b-col md="6">
          <b-button
            variant="outline-primary"
            @click="repeateAgain($event, 0)"
            class="mb-1"
            block
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Horario continuo</span>
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            variant="outline-primary"
            @click="repeateAgain($event, 1)"
            class="mb-1"
            block
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Horario ocasional</span>
          </b-button>
        </b-col>
      </b-row>
      <div>
        <b-row
          v-for="(horario, index) in horarios"
          :id="'horario-' + index"
          :key="'horario-' + index"
          ref="row"
        >
          <b-col md="12">
            <v-select
              v-if="horario.tipo === 0"
              label="title"
              v-model="horario.dias"
              multiple
              :options="options"
              :reduce="(d) => d.value"
              :closeOnSelect="false"
            />
            <b-form-datepicker
              :id="'date-picker' + index"
              :min="minDate"
              v-else
              locale="es"
              v-model="horario.fecha"
            />
          </b-col>

          <b-col md="5">
            <label for="timepicker-placeholder">Hora Inicio</label>
            <b-form-timepicker
              local="es"
              v-model="horario.apertura"
              placeholder="Inicio"
              label-close-button="Aceptar"
            />
          </b-col>

          <b-col md="5">
            <label for="timepicker-placeholder">Hora Fin</label>
            <b-form-timepicker
              placeholder="Fin"
              local="es"
              v-model="horario.cierre"
              label-close-button="Aceptar"
            />
          </b-col>

          <b-col md="2">
            <b-button
              block
              variant="danger"
              class="mt-2 px-0"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </div>

      <template #footer>
        <b-button
          class="m-2"
          @click="saveSchedule"
          variant="primary"
          :disabled="loading"
        >
          <b-spinner v-if="loading" small label="Loading..." />
          <div v-else>Guardar</div>
        </b-button>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BSidebar,
  BSpinner,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCol,
    BRow,
    vSelect,
    BButton,
    BSidebar,
    BSpinner,
    BFormTimepicker,
    BFormDatepicker,
    ToastificationContent,
  },
  props: {
    data: {
      type: String,
      required: true,
    },
    warehouseID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      minDate: new Date(),
      loading: false,
      horarios: [],
      options: [
        {
          title: "Lunes",
          value: 0,
        },
        {
          title: "Martes",
          value: 1,
        },
        {
          title: "Miercoles",
          value: 2,
        },
        {
          title: "Jueves",
          value: 3,
        },
        {
          title: "Viernes",
          value: 4,
        },
        {
          title: "Sabado",
          value: 5,
        },
        {
          title: "Domingo",
          value: 6,
        },
      ],
    };
  },
  beforeMount() {
    if (!!this.data) {
      this.horarios = JSON.parse(this.data);
    }
  },
  methods: {
    onClick(event) {
      event.preventDefault();
      this.show = true;
    },
    repeateAgain(event, tipo) {
      event.preventDefault();
      this.horarios.push({
        dias: [],
        tipo: tipo,
        fecha: "",
        cierre: "",
        apertura: "",
      });
    },
    removeItem(index) {
      this.horarios.splice(index, 1);
    },
    saveSchedule(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Bodegas",
          body: JSON.stringify({
            otp: "HU",
            empresa: 2,
            IDBodega: this.warehouseID,
            tokenSesion: getAuthToken(),
            bg_horario: JSON.stringify(this.horarios),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se guardo correctamente los horarios de la Bodega.",
                title: "Exito",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: res.data.mensaje,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>